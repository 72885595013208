import Layout from "../../components/layout"
import React from "react"
import SEO from "../../components/seo"

function Grant3() {
  return (
    <Layout>
      <SEO title="Grant 3 - ZSP Nidek" />
      <h1>Grant 3</h1>
      <ul>
        <li>
          <a
            href="../../../documents/grant3/deklaracja_uczestnictwa.pdf"
            download="deklaracja_uczestnictwa.pdf"
          >
            Deklaracja uczestnictwa (188KB | pdf)
          </a>
        </li>
        <li>
          <a
            href="../../../documents/grant3/formularz_zgloszeniowy.pdf"
            download="formularz_zgloszeniowy.pdf"
          >
            Formularz zgłoszeniowy (381KB | pdf)
          </a>
        </li>
        <li>
          <a
            href="../../../documents/grant3/regulamin_rekrutacji_małopolska_tarcza_antykryzysowa_uzupełniony.docx"
            download="regulamin_rekrutacji_małopolska_tarcza_antykryzysowa_uzupełniony.docx"
          >
            Regulamin rekrutacji Małopolska Tarcza Antykryzysowa (397KB | docx)
          </a>
        </li>
        <li>
          <a
            href="../../../documents/grant3/zgoda_na_przetwarzanie_wizerunku.pdf"
            download="zgoda_na_przetwarzanie_wizerunku.pdf"
          >
            Zgoda na przetwarzanie wizerunku (233KB | pdf)
          </a>
        </li>
      </ul>
    </Layout>
  )
}

export default Grant3
